import React, { useContext } from 'react';
import {
  useIrzFacilitiesColumns,
  useOvzFacilitiesColumns,
  useEmpowermentsAsEmpowererColumns,
  useEmpowermentsAsAgentColumns,
  useWaterUsagePlacesColumns,
  useBoilerPermissionsOZOColumns,
  useBoilerPermissionsManufacturerColumns,
} from '../subjects-columns';
import {
  FormPanel,
  FormTableField,
  EmptyComponent,
  UserContext,
  useFormSelector,
} from '@eas/common-web';
import { FormPanelCountByName } from '../../../components/form/form-panel-summary/form-panel-count';
import {
  Me,
  SubjectUnion,
  PermissionsState,
  OvzFacility,
  IrzFacility,
} from '../../../models';
import { Permission } from '../../../enums';
import { BoilerPermissionContext } from '../../../components/boiler-permission/boiler-permission-context';
import { InactiveColoredTableFieldRow } from '../../../components/form/inactive-colored/inactive-colored-table-field-row';
import { stubFalse } from 'lodash';

export function SubjectsAdvancedFields() {
  const irzFacilitiesColumns = useIrzFacilitiesColumns();
  const ovzFacilitiesColumns = useOvzFacilitiesColumns();
  const empowermentsAsEmpowererColumns = useEmpowermentsAsEmpowererColumns();
  const empowermentsAsAgentColumns = useEmpowermentsAsAgentColumns();
  const waterUsagePlacesColumns = useWaterUsagePlacesColumns();
  const boilerPermissionsOZOColumns = useBoilerPermissionsOZOColumns();
  const boilerPermissionsManufacturerColumns = useBoilerPermissionsManufacturerColumns();

  const { subjectId } = useFormSelector((data: SubjectUnion) => ({
    subjectId: data?.id,
  }));

  const { hasPermission } = useContext<UserContext<Me, PermissionsState>>(
    UserContext
  );

  const { hasBoilerPermissions } = useContext(BoilerPermissionContext);

  const showIrzFacilities =
    hasPermission(Permission.IrzFacility.IRZ_LIST_ALL, {
      searchInSubjectPerms: true,
    }) ||
    hasPermission(Permission.Subject.SUBJECT_LIST_ALL, {
      searchInSubjectPerms: true,
    }) ||
    hasPermission(Permission.IrzFacility.IRZ_LIST_OWN, {
      subjectId,
    });

  const showOvzFacilities =
    hasPermission(Permission.OvzFacility.OVZ_LIST_ALL, {
      searchInSubjectPerms: true,
    }) ||
    hasPermission(Permission.Subject.SUBJECT_LIST_ALL, {
      searchInSubjectPerms: true,
    }) ||
    hasPermission(Permission.OvzFacility.OVZ_LIST_OWN, {
      subjectId,
    });

  const showEmpowerments =
    hasPermission(Permission.Empowerment.EMPOWERMENT_LIST_ALL, {
      searchInSubjectPerms: true,
    }) ||
    hasPermission(Permission.Empowerment.EMPOWERMENT_LIST_OWN, {
      subjectId,
    });

  const showWaterUsagePlaces =
    hasPermission(
      Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_LIST_ALL,
      { searchInSubjectPerms: true }
    ) ||
    hasPermission(Permission.Subject.SUBJECT_LIST_ALL, {
      searchInSubjectPerms: true,
    }) ||
    hasPermission(
      Permission.WaterUsagePlace.WATER_USAGE_PLACE_DECISION_LIST_OWN,
      {
        subjectId,
      }
    );

  const showOzoPermissions =
    hasPermission(Permission.Ozo.OZO_PERMISSION_LIST_ALL, {
      searchInSubjectPerms: true,
    }) ||
    hasPermission(Permission.Ozo.OZO_PERMISSION_LIST_OWN, {
      searchInSubjectPerms: true,
    }) ||
    hasBoilerPermissions;

  return (
    <>
      {showIrzFacilities && (
        <FormPanel
          label="Provozovny IRZ"
          expandable
          defaultExpanded={false}
          summary={
            <FormPanelCountByName<IrzFacility>
              name="irzFacilities"
              columns={irzFacilitiesColumns.filter((_, i) => i !== 0)}
              permission={Permission.Subject.SUBJECT_DETAIL_EXPORT}
            />
          }
        >
          <FormTableField<IrzFacility>
            name="irzFacilities"
            ToolbarComponent={EmptyComponent}
            labelOptions={{
              hide: true,
            }}
            maxRows={15}
            visibleActionsColumn={false}
            showDetailBtnCond={() => false}
            columns={irzFacilitiesColumns}
            RowComponent={InactiveColoredTableFieldRow}
            showRadioCond={stubFalse}
            layoutOptions={{
              noSpacing: true,
            }}
          />
        </FormPanel>
      )}
      {showOvzFacilities && (
        <FormPanel
          label="Provozovny OVZ"
          expandable
          defaultExpanded={false}
          summary={
            <FormPanelCountByName<OvzFacility>
              name="ovzFacilities"
              columns={ovzFacilitiesColumns.filter((_, i) => i !== 0)}
              permission={Permission.Subject.SUBJECT_DETAIL_EXPORT}
            />
          }
        >
          <FormTableField<OvzFacility>
            name="ovzFacilities"
            ToolbarComponent={EmptyComponent}
            labelOptions={{
              hide: true,
            }}
            maxRows={15}
            showDetailBtnCond={() => false}
            visibleActionsColumn={false}
            columns={ovzFacilitiesColumns}
            RowComponent={InactiveColoredTableFieldRow}
            showRadioCond={stubFalse}
            layoutOptions={{
              noSpacing: true,
            }}
          />
        </FormPanel>
      )}
      {showEmpowerments && (
        <FormPanel
          label="Zmocnění - Udělená (subjekt v roli zmocnitele)"
          expandable
          defaultExpanded={false}
          summary={
            <FormPanelCountByName
              name="empowermentsAsEmpowerer"
              columns={empowermentsAsEmpowererColumns.filter((_, i) => i !== 0)}
              permission={Permission.Subject.SUBJECT_DETAIL_EXPORT}
            />
          }
        >
          <FormTableField
            name="empowermentsAsEmpowerer"
            ToolbarComponent={EmptyComponent}
            labelOptions={{
              hide: true,
            }}
            maxRows={15}
            showDetailBtnCond={() => false}
            visibleActionsColumn={false}
            columns={empowermentsAsEmpowererColumns}
            layoutOptions={{
              noSpacing: true,
            }}
          />
        </FormPanel>
      )}
      {showEmpowerments && (
        <FormPanel
          label="Zmocnění - Přijatá (subjekt v roli zmocněnce)"
          expandable
          defaultExpanded={false}
          summary={
            <FormPanelCountByName
              name="empowermentsAsAgent"
              columns={empowermentsAsAgentColumns.filter((_, i) => i !== 0)}
              permission={Permission.Subject.SUBJECT_DETAIL_EXPORT}
            />
          }
        >
          <FormTableField
            name="empowermentsAsAgent"
            ToolbarComponent={EmptyComponent}
            labelOptions={{
              hide: true,
            }}
            visibleActionsColumn={false}
            maxRows={15}
            showDetailBtnCond={() => false}
            columns={empowermentsAsAgentColumns}
            layoutOptions={{
              noSpacing: true,
            }}
          />
        </FormPanel>
      )}
      {showWaterUsagePlaces && (
        <FormPanel
          label="Místa užívání vody"
          expandable
          defaultExpanded={false}
          summary={
            <FormPanelCountByName
              name="waterUsagePlaces"
              columns={waterUsagePlacesColumns.filter((_, i) => i !== 0)}
              permission={Permission.Subject.SUBJECT_DETAIL_EXPORT}
            />
          }
        >
          <FormTableField
            name="waterUsagePlaces"
            ToolbarComponent={EmptyComponent}
            labelOptions={{
              hide: true,
            }}
            maxRows={15}
            visibleActionsColumn={false}
            showDetailBtnCond={() => false}
            columns={waterUsagePlacesColumns}
            RowComponent={InactiveColoredTableFieldRow}
            showRadioCond={stubFalse}
            layoutOptions={{
              noSpacing: true,
            }}
          />
        </FormPanel>
      )}
      {showOzoPermissions && (
        <>
          <FormPanel
            label="Oprávnění OZO - v roli subjektu OZO"
            expandable
            defaultExpanded={false}
            summary={
              <FormPanelCountByName
                name="boilerPermissionsOZOSubject"
                columns={boilerPermissionsOZOColumns.filter((_, i) => i !== 0)}
                permission={Permission.Subject.SUBJECT_DETAIL_EXPORT}
              />
            }
          >
            <FormTableField
              name="boilerPermissionsOZOSubject"
              ToolbarComponent={EmptyComponent}
              labelOptions={{
                hide: true,
              }}
              maxRows={15}
              visibleActionsColumn={false}
              showDetailBtnCond={() => false}
              columns={boilerPermissionsOZOColumns}
              layoutOptions={{
                noSpacing: true,
              }}
            />
          </FormPanel>
          <FormPanel
            label="Oprávnění OZO - v roli výrobce"
            expandable
            defaultExpanded={false}
            summary={
              <FormPanelCountByName
                name="boilerPermissionsManufacturer"
                columns={boilerPermissionsManufacturerColumns.filter(
                  (_, i) => i !== 0
                )}
                permission={Permission.Subject.SUBJECT_DETAIL_EXPORT}
              />
            }
          >
            <FormTableField
              name="boilerPermissionsManufacturer"
              ToolbarComponent={EmptyComponent}
              labelOptions={{
                hide: true,
              }}
              maxRows={15}
              showDetailBtnCond={() => false}
              visibleActionsColumn={false}
              columns={boilerPermissionsManufacturerColumns}
              layoutOptions={{
                noSpacing: true,
              }}
            />
          </FormPanel>
        </>
      )}
    </>
  );
}
