import React, { useContext } from 'react';
import {
  FormPanel,
  FormTableField,
  FormFieldContext,
  EmptyComponent,
  RemoteTableField,
  EvidenceContext,
  UserContext,
  DetailContext,
} from '@eas/common-web';
import { FormPanelCountByName } from '../../../components/form/form-panel-summary/form-panel-count';
import { InactiveColoredTableFieldRow } from '../../../components/form/inactive-colored/inactive-colored-table-field-row';
import { SubjectsAgendasToolbar } from '../overrides/subjects-toolbar-agendas';
import { SubjectsUsersToolbar } from '../overrides/subjects-toolbar-users';
import { UsersTableFieldRow } from '../overrides/subjects-row-user';
import { SubjectAgendaDto } from '../subjects-types';
import { SUBJECT_USER_SOURCE } from '../subjects-hooked-sources';
import {
  useAgendaColumns,
  useUserColumns,
  useSubjectRoleColumns,
} from '../subjects-columns';
import { Permission } from '../../../enums';

export function SubjectsBasicFields() {
  const { hookedSources } = useContext(EvidenceContext);
  const { hasPermission } = useContext(UserContext);
  const { source } = useContext(DetailContext);

  const isAdmin = hasPermission(Permission.Subject.SUBJECT_UPDATE_ALL, {
    searchInSubjectPerms: true,
  });

  const canSeeUsers =
    hasPermission(Permission.Subject.SUBJECT_DETAIL_OWN, {
      subjectId: source?.data?.id,
      searchOnlyInSubjectPerms: true,
    }) ||
    hasPermission(Permission.Subject.SUBJECT_DETAIL_ALL, {
      searchInSubjectPerms: true,
    });

  const agendaColumns = useAgendaColumns();
  const userColumns = useUserColumns(isAdmin);
  const subjectRoleColumns = useSubjectRoleColumns();

  const userSource = hookedSources[SUBJECT_USER_SOURCE]?.source;

  return (
    <>
      <FormFieldContext.Provider value={{ disabled: false }}>
        <FormPanel
          label="Agendy"
          expandable
          defaultExpanded={false}
          summary={
            <FormPanelCountByName
              name="agendas"
              columns={agendaColumns}
              permission={Permission.Subject.SUBJECT_DETAIL_EXPORT}
            />
          }
        >
          <FormTableField<SubjectAgendaDto>
            name="agendas"
            ToolbarComponent={SubjectsAgendasToolbar}
            labelOptions={{
              hide: true,
            }}
            maxRows={15}
            showDetailBtnCond={() => false}
            columns={agendaColumns}
            RowComponent={InactiveColoredTableFieldRow}
            layoutOptions={{
              noSpacing: true,
            }}
          />
        </FormPanel>
        {canSeeUsers && (
          <FormPanel
            label="Uživatelé"
            expandable
            defaultExpanded={false}
            summary={
              <FormPanelCountByName
                name="users"
                count={userSource.count}
                items={userSource.items}
                columns={[
                  ...(isAdmin
                    ? userColumns.filter((_, i) => i !== 0)
                    : userColumns),
                  // {
                  //   name: 'Role',
                  //   datakey: 'userRoles',
                  //   width: 200,
                  //   CellComponent: function Name(props) {
                  //     return (
                  //       <TableFieldCells.TextCell
                  //         {...props}
                  //         value={props.rowValue.userRoles
                  //           ?.map((role) => role.name)
                  //           .join(', ')}
                  //       />
                  //     );
                  //   },
                  // },
                ]}
                permission={Permission.Subject.SUBJECT_DETAIL_EXPORT}
              />
            }
          >
            <RemoteTableField
              disabled={false}
              columns={userColumns}
              source={userSource}
              maxRows={7}
              ToolbarComponent={SubjectsUsersToolbar}
              RowComponent={UsersTableFieldRow}
              highlightSelectedRow={true}
              showDetailBtnCond={() => false}
            />
            {/* <FormTableField<SubjectUserDto>
            name="users"
            ToolbarComponent={SubjectsUsersToolbar}
            RowComponent={UsersTableFieldRow}
            labelOptions={{
              hide: true,
            }}
            maxRows={20}
            showDetailBtnCond={() => false}
            columns={userColumns}
            layoutOptions={{
              noSpacing: true,
            }}
          /> */}
          </FormPanel>
        )}
        <FormPanel
          label="Subjektové role"
          expandable
          defaultExpanded={false}
          summary={
            <FormPanelCountByName
              name="roles"
              columns={subjectRoleColumns}
              permission={Permission.Subject.SUBJECT_DETAIL_EXPORT}
            />
          }
        >
          <FormTableField
            name="roles"
            ToolbarComponent={EmptyComponent}
            labelOptions={{
              hide: true,
            }}
            maxRows={15}
            showRadioCond={() => false}
            showDetailBtnCond={() => false}
            visibleActionsColumn={false}
            columns={subjectRoleColumns}
            layoutOptions={{
              noSpacing: true,
            }}
          />
        </FormPanel>
      </FormFieldContext.Provider>
    </>
  );
}
