import React, { useContext, useState } from 'react';
import {
  DetailContext,
  DetailHandle,
  UserContext,
  abortableFetch,
  FormAutocomplete,
  ApiFilterOperation,
  useFormSelector,
  FormContext,
  useEventCallback,
  EvidenceStateAction,
  NavigationContext,
  Callback,
  Filter,
} from '@eas/common-web';
import MuiDialogActions from '@material-ui/core/DialogActions';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import MuiButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Map } from '../../../components/map/map';
import { MarkerLayer } from '../../../components/map/marker-layer';
import { Marker } from '../../../components/map/marker';
import { dictionaryLabelMapper } from '../../../components/form/mappers/label-mappers';
import { useProductionSites } from '../../production-site/production-sites-api';
import { MapMarkers } from '../irz-facilities-map-markers';
import { EvidenceAPI, EvidenceUrl, Permission } from '../../../enums';
import {
  IrzFacility,
  Me,
  Wgs84Coordinates,
  ProductionSite,
} from '../../../models';

const useStyles = makeStyles(() => ({
  buttonLabel: {
    textTransform: 'uppercase',
  },
  actions: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    padding: '10px 24px',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  delimiter: { width: '100%', marginBottom: 50 },
}));

export function useAssignProductionSiteDialog() {
  const { source } = useContext<DetailHandle<IrzFacility>>(DetailContext);
  const { navigate } = useContext(NavigationContext);
  const { hasPermission } = useContext<UserContext<Me>>(UserContext);
  const [productionSitesCoordinates, setProductionSitesCoordinates] = useState<
    (Wgs84Coordinates & { id: string })[]
  >([]);

  const classes = useStyles();

  const callApi = (
    id: string,
    { productionSite }: { productionSite: ProductionSite }
  ) => {
    return abortableFetch(
      `${EvidenceAPI.IRZ_FACILITIES}/${id}/production-site`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'PUT',
        body: productionSite.id,
      }
    );
  };

  const loadNearbyProductionSitesCoordinates = async () => {
    const orFilter: Filter = {
      operation: ApiFilterOperation.OR,
      filters: [
        {
          operation: ApiFilterOperation.GEO_DISTANCE,
          field: 'wgs84Point',
          point: {
            lat: source?.data?.wgs84Coordinates?.lat,
            lon: source?.data?.wgs84Coordinates?.lon,
          },
          distance: 50,
          unit: 'KILOMETERS',
        },
      ],
    };

    if (source.data?.productionSite) {
      orFilter.filters!.push({
        operation: ApiFilterOperation.EQ,
        field: 'id',
        value: source?.data?.productionSite?.id,
      });
    }

    const response = await fetch(
      `${EvidenceAPI.PRODUCTION_SITES}/coordinates`,
      {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({
          filters: [orFilter],
        }),
      }
    );

    const json = await response.json();
    setProductionSitesCoordinates(json.items);

    return true;
  };

  const FormFields = ({
    onConfirm,
    onCancel,
  }: {
    onConfirm?: Callback;
    onCancel?: () => void;
  }) => {
    const { source } = useContext<DetailHandle<IrzFacility>>(DetailContext);
    const { setFieldValue } = useContext(FormContext);
    const productionSitesSource = useProductionSites();

    const { productionSite } = useFormSelector((data: IrzFacility) => ({
      productionSite: data.productionSite,
    }));

    const isSiteAssigned = (id: string) => productionSite?.id === id;

    const handleRedirect = useEventCallback(() => {
      navigate(EvidenceUrl.PRODUCTION_SITES, false, {
        action: EvidenceStateAction.NEW_ITEM,
        data: {
          facilities: [source.data],
          irzFacilitiesCoordinates: [
            {
              ...source.data?.wgs84Coordinates,
              id: source?.data?.id,
              assigned: true,
            },
          ],
        },
      });
    });

    const handleClose = useEventCallback(() => {
      if (onCancel) {
        onCancel();
      }
    });

    const handleSubmit = useEventCallback(async (e) => {
      onConfirm!(e);
    });

    return (
      <>
        <Map
          height={500}
          defaultWgs84Position={source.data?.wgs84Coordinates}
          coordinatesFormat="WGS84"
          defaultZoom={15}
        >
          <MarkerLayer enableClusterer={false}>
            <Marker
              lat={source.data?.wgs84Coordinates?.lat ?? ''}
              lng={source.data?.wgs84Coordinates?.lon ?? ''}
            />
            <MapMarkers
              items={(productionSitesCoordinates ?? [])?.map((site) => ({
                ...site,
                assigned: isSiteAssigned(site?.id),
              }))}
              showAddBtn={(id) => !isSiteAssigned(id)}
              showRemoveBtn={isSiteAssigned}
              onRemoveBtnClick={() =>
                setFieldValue('productionSite', undefined)
              }
              onAddBtnClick={(data) => setFieldValue('productionSite', data)}
            />
          </MarkerLayer>
        </Map>
        <FormAutocomplete
          label="Průmyslová místa"
          name="productionSite"
          source={productionSitesSource}
          labelMapper={dictionaryLabelMapper}
          tooltipMapper={dictionaryLabelMapper}
        />

        <div className={classes.delimiter} />

        <MuiDialogActions classes={{ root: classes.actions }}>
          <ButtonGroup size="small" variant="outlined">
            <MuiButton
              type="submit"
              onClick={handleSubmit}
              variant="outlined"
              color="primary"
              disabled={!productionSite}
            >
              <Typography classes={{ root: classes.buttonLabel }}>
                Potvrdit
              </Typography>
            </MuiButton>
            <MuiButton
              type="submit"
              onClick={handleRedirect}
              variant="outlined"
              color="primary"
              disabled={!!productionSite}
            >
              <Typography classes={{ root: classes.buttonLabel }}>
                Vytvořit nové místo
              </Typography>
            </MuiButton>
            <MuiButton variant="outlined" onClick={handleClose}>
              <Typography classes={{ root: classes.buttonLabel }}>
                Zavřít
              </Typography>
            </MuiButton>
          </ButtonGroup>
        </MuiDialogActions>
      </>
    );
  };

  const showBtn =
    source?.data?.id &&
    hasPermission(Permission.IrzFacility.IRZ_UPDATE_PRODUCTION_SITE);

  return {
    callApi,
    showBtn,
    FormFields,
    loadNearbyProductionSitesCoordinates,
  };
}
