/**
 * Subject permissions
 */
export enum PermissionSubject {
  /** Přehled všech subjektů */
  SUBJECT_LIST_ALL = 'SUBJECT_LIST_ALL',

  /** Přehled svých subjektů */
  SUBJECT_LIST_OWN = 'SUBJECT_LIST_OWN',

  /** Přehled uživatelů všech subjektů bez OÚ */
  SUBJECT_LIST_WITHOUT_PERSONAL = 'SUBJECT_LIST_WITHOUT_PERSONAL',

  /** Autocomplete výběr všech subjektů */
  SUBJECT_AUTOCOMPLETE_ALL = 'SUBJECT_AUTOCOMPLETE_ALL',

  /** Autocomplete výběr svých subjektů */
  SUBJECT_AUTOCOMPLETE_OWN = 'SUBJECT_AUTOCOMPLETE_OWN',

  /** Detail všech subjektů */
  SUBJECT_DETAIL_ALL = 'SUBJECT_DETAIL_ALL',

  /** Detail všech subjektů pro export */
  SUBJECT_DETAIL_EXPORT = 'SUBJECT_DETAIL_EXPORT',

  /** Detail svých subjektů */
  SUBJECT_DETAIL_OWN = 'SUBJECT_DETAIL_OWN',

  /** Detail uživatele bez OÚ */
  SUBJECT_DETAIL_WITHOUT_PERSONAL = 'SUBJECT_DETAIL_WITHOUT_PERSONAL',

  /** Vytvoření (registrace) subjektu */
  SUBJECT_CREATE = 'SUBJECT_CREATE',

  /** Editace všech subjektů */
  SUBJECT_UPDATE_ALL = 'SUBJECT_UPDATE_ALL',

  /** Editace svých subjektů */
  SUBJECT_UPDATE_OWN = 'SUBJECT_UPDATE_OWN',

  /** Editace SIČ subjektů */
  SUBJECT_UPDATE_SIC = 'SUBJECT_UPDATE_SIC',

  /** Zneplatnění subjektu */
  SUBJECT_INVALIDATE = 'SUBJECT_INVALIDATE',

  /** Obnovení subjektu */
  SUBJECT_REVALIDATE = 'SUBJECT_REVALIDATE',

  /** Synchronizace subjektu */
  SUBJECT_SYNC = 'SUBJECT_SYNC',

  /** Zapnout/vypnout synchonizaci subjektu */
  SUBJECT_SYNC_TOGGLE = 'SUBJECT_SYNC_TOGGLE',

  /** Přehled všech žádostí o registraci subjektů */
  SUBJECT_REQUEST_LIST_ALL = 'SUBJECT_REQUEST_LIST_ALL',

  /** Přehled svých žádostí o registraci subjektů */
  SUBJECT_REQUEST_LIST_OWN = 'SUBJECT_REQUEST_LIST_OWN',

  /** Detail všech žádostí o registraci subjektu */
  SUBJECT_REQUEST_DETAIL_ALL = 'SUBJECT_REQUEST_DETAIL_ALL',

  /** Detail svých žádostí o registraci subjektu */
  SUBJECT_REQUEST_DETAIL_OWN = 'SUBJECT_REQUEST_DETAIL_OWN',

  /** Autorizace registrací všech subjektů */
  SUBJECT_REQUEST_AUTHORIZE_ALL = 'SUBJECT_REQUEST_AUTHORIZE_ALL',

  /** Autorizace registrací svých subjektů */
  SUBJECT_REQUEST_AUTHORIZE_OWN = 'SUBJECT_REQUEST_AUTHORIZE_OWN',

  /** Dokončení registrace subjektu */
  SUBJECT_REQUEST_FINISH = 'SUBJECT_REQUEST_FINISH',

  /** Přehled subjektů OZO */
  SUBJECT_LIST_OZO = 'SUBJECT_LIST_OZO',

  /** Detail subjektů OZO */
  SUBJECT_DETAIL_OZO = 'SUBJECT_DETAIL_OZO',
}
